import styled from 'styled-components'
import theme from '../../../themes/default'
import linkedin from '../../../images/linkedin.svg'
import github from '../../../images/github.svg'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const TabContainer = styled.div`
  display: flex;
  align-items: end;
  width: 50%;
  @media (max-width: 768px) {
    width: 70%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`

const Tab = styled.div`
  display: flex;
  align-items: flex-end;
  width: 66%;
  height: 64px;
  background: ${theme.moduleBg};
  border-radius: 18px 18px 0 0;
`

const RoundedCorner = styled.div`
  width: 16px;
  height: 16px;
  background: ${theme.moduleBg};
  z-index: 0;
  &:before {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    transform: translateY(-16px);
    border-radius: 50%;
    background: ${theme.backgroundBg};
  }
`

const Socials = styled.div`
  display: flex;
  z-index: 2;
`

const Social = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  padding: 0.25rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: linear-gradient(25deg, ${theme.skyBlue} 0%, #00ffc1 100%);
`

export default function TopRow() {
  return (
    <Wrapper>
      <TabContainer>
        <Tab />
        <RoundedCorner />
      </TabContainer>
      <Socials>
        <Social href='https://www.linkedin.com/in/kevin-chuang-679a275a/' target='_blank'>
          <img src={linkedin} alt='linkedin logo' />
        </Social>
        <Social href='https://github.com/kchuang7' target='_blank'>
          <img src={github} alt='github logo' />
        </Social>
      </Socials>
    </Wrapper>
  )
}
