import styled from 'styled-components'
import Footer from '../Home/Footer'
import arrow from '../../images/arrow.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: #eee;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 968px;
  padding: 1rem;
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    padding: 1rem 0;
    width: 100%;
  }
`

const Navbar = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 3rem;
  width: 100%;
  background: linear-gradient(135deg, #6b258b 0%, #6baec1 100%);
  box-shadow: 0 0 12px 0 #bbb;
  border-radius: 32px;
  @media (max-width: 480px) {
    margin-bottom: 2rem;
    width: 90%;
  }
`

const ReturnLink = styled.a`
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  opacity: 0.8;
  transition: 0.2s;
  &:hover {
    opacity: 1;
  }
`

const Arrow = styled.img`
  margin-right: 0.8rem;
  transform: rotate(180deg);
`

const ArticleContainer = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  @media (max-width: 480px) {
    padding: 1rem;
  }
`

export default function Article({ children }) {
  return (
    <Wrapper>
      <Container>
        <Navbar>
          <ReturnLink href='/'>
            <Arrow src={arrow} width='24' />
            Home
          </ReturnLink>
        </Navbar>
        <ArticleContainer>
          {children}
        </ArticleContainer>
        <Footer isArticle />
      </Container>
    </Wrapper>
  )
}
