import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createHashRouter,
  RouterProvider,
} from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import './index.css'
import Home from './routes/Home'
import OptiswapArticle from './routes/OptiswapArticle'
import FutureArticle from './routes/FutureArticle'
import PollificArticle from './routes/PollificArticle'
import Error from './components/Error'

const router = createHashRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/projects/optiswap',
    element: <OptiswapArticle />
  },
  {
    path: '/projects/future',
    element: <FutureArticle />
  },
  {
    path: '/projects/pollific',
    element: <PollificArticle />
  },
  {
    path: '*',
    element: <Error />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
)
