import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import theme from '../themes/default'
import Background from '../components/Home/Background'
import MainSection from '../components/Home/MainSection'
import Projects from '../components/Home/Projects'
import Footer from '../components/Home/Footer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: white;
`

const HeaderModule = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 2rem;
  padding: 1rem;
  background: linear-gradient(135deg, ${theme.galaxyPurpleLight} 0%, ${theme.moduleBg} 100%);
  border-radius: 18px;
  font-size: 2.4rem;
  @media (max-width: 480px) {
    margin: 1rem 0.5rem;
  }
`

export default function Home() {
  return (
    <Background>
      <Helmet><title>Portfolio | kevinchuang.dev</title></Helmet>
      <Wrapper>
        <MainSection />
        <HeaderModule>Projects</HeaderModule>
        <Projects />
        <Footer />
      </Wrapper>
    </Background>
  )
}
