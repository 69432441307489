import { Helmet } from 'react-helmet-async'
import Article from '../components/Article'
import Image from '../components/Article/Image'
import TitleSection from '../components/Article/TitleSection'
import Paragraph from '../components/Article/Paragraph'
import Header from '../components/Article/Header'
import useScrollToTop from '../components/ScrollToTop'
// images
import shortHome from '../images/articles/example_home_page_short.png'
import pollificPost from '../images/articles/pollific_post.png'
import pollificAnswer from '../images/articles/pollific_answer.png'
import pollificDiagram from '../images/articles/pollific_diagram.png'

export default function FutureArticle() {
  useScrollToTop()
  return (
    <Article>
      <Helmet><title>Pollific | kevinchuang.dev</title></Helmet>
      <TitleSection
        title='Democratizing Public Sentiment with Polls from Scratch'
        sub='Pollific is a real-time social polling platform loosely inspired by existing social media while trying to fix their flaws. This is a reboot of an early web dev project that I never got to finish before.'
        dateTime='2022-09-15'
        dateStr='Sep 15, 2022'
      />
      <Image src={shortHome} caption='Top of a typical Pollific home page.' />
      <Paragraph>
        My main motivation for building Pollific is to empower the average social media user to both participate in and see unbiased discourse.
        What felt like insular echo chambers on sites like Reddit and Twitter seemed like an addressable problem.
        While I know now that tribalism is very attractive from a monetization perspective, I still fought tooth and nail trying to validate Pollific in any niche that would need it.
      </Paragraph>
      <Header>How It Works</Header>
      <Paragraph>
        Pollific is kind of like Reddit for polls. The site is sectioned into communities which contain their own list of posts that users can interact with.
        A post is simply a poll that when expanded, offer rich data visualizations of all its answers.
      </Paragraph>
      <Image src={pollificPost} caption='A Pollific post page with answer and demographic breakdown.' />
      <Paragraph>
        Users can answer, comment on, or share posts. The comment section is inspired by Reddit's, sporting infinite nesting capabilities.
        Sharing a post is pretty self-explanatory, and answering a post involves the user selecting one of several possible answers and optionally attaching opt-in demographic data with their answer submission.
      </Paragraph>
      <Image src={pollificAnswer} caption='Post answer modal with mandatory answer selection and optional demographic attachment.' />
      <Paragraph>
        While attaching demographics to answer submissions is optional, users are highly encouraged to do so for the betterment of the data visualization experience for everyone.
        We take great care in anonymizing all collected data, ensuring that submitted demographic data is tied to the answer only, and not the user.
      </Paragraph>
      <Paragraph>
        Another cool feature is the option for users to attach their rough geolocation with their answers.
        This shows up on a geographical heatmap that reveals where the answers are coming from. 
        Users can even select individual answers to see the full demographic and geolocation breakdown of each one.
      </Paragraph>
      <Header>The Tech</Header>
      <Paragraph>
        Not only did I have big ambitions for Pollific as a social media startup, but I also wanted to make this an opportunity to roll out a substantial containerized cloud deployment.
        What follows is a brief diagram detailing Pollific's architecture on AWS.
      </Paragraph>
      <Image src={pollificDiagram} />
      <Header>Architectural Decision Making</Header>
      <Paragraph>
        Requirements mostly dictated architectural design for Pollific.
        The site needed to be responsive and real-time, while persisting user/post data and rapidly updating communities from votes, answers, and comments.
      </Paragraph>
      <Paragraph>
        This meant that using NoSQL like MongoDB for flexible, high-throughput storage and Redis for post list updates made sense.
        I also opted for a node.js/ExpressJS backend with NGINX as a reverse proxy between the web services and AWS ELBs.
      </Paragraph>
      <Paragraph>
        The entire deployment was containerized and orchestrated with Kubernetes through the use of an open source tool called kops as well as AWS CLI.
        APIs are REST as opposed to GraphQL, and kops ended up being mostly free compared to EKS.
      </Paragraph>
      <Paragraph>
        For CI/CD, I opted to use Github Actions over competitors such as CircleCI due to GA's integration with GitHub itself where I hosted my repository.
        In hindsight, this decision was time-consuming due to the relatively early stage that GitHub Actions was at during initial deployment.
      </Paragraph>
      <Paragraph>
        For the frontend, I built Pollific with React, Redux, socket.io for cross-browser WS, Amcharts for charting, and Leaflet for mapping.
        I was already familiar with React and also went with Redux over Flux due to the former's simplicity and performance. 
      </Paragraph>
      <Paragraph>
        For testing, outside of adhering to best practices such as convention over configuration, DRY, StandardJS, ESLint, etc., I utilized a combination of Mocha, Chai, and Supertest for API and unit testing. 
        These tests were integrated into CI pipelines before deployment.
      </Paragraph>
      <Header>Retrospective Improvements</Header>
      <Paragraph>
        I think I did a lot of things well, but also made plenty of mistakes throughout Pollific's journey. After all, we're just human at the end of the day.
      </Paragraph>
      <Paragraph>
        Early on, I adopted a build tool for the primary reason that it was new and hyped.
        Unfortunately, it broke the CI pipeline multiple times due to bugs. I eventually had to migrate back to Webpack which was a much more established player and never had build problems since.
      </Paragraph>
      <Paragraph>
        Not using React Router was also a big oversight on my part.
        While my multi-page setup did work, it was clunky, somewhat redundant, and lacked the overall elegance that a proper router would have provided.
      </Paragraph>
      <Paragraph>
        While I did containerize my different services, the node.js backend remained monolithic throughout Pollific's lifespan.
        Had I needed to scale certain parts of my API for example, it would have been difficult to separate the bottlenecked features out from the rest.
      </Paragraph>
      <Paragraph>
        At the time of the beta launch, I was prioritizing deployment speed and overlooked IaC solutions such as Terraform and Helm.
        Had I done so, I would have had an easier time handling application deployment as a whole compared to juggling dozens of YAMLs at a time.
      </Paragraph>
      <Paragraph>
        I'm a big believer in avoiding premature optimization, but also recognize the performance increases from adopting an SSR solution like NextJS for a Pollific use case as well.
      </Paragraph>
      <Paragraph>
        Finally, on the business side of things, I fell into one of the most common startup traps of building a product in search of a solution.
        Had I gone the route of finding and validating a problem space first then building a solution for it, I would likely have had a much higher chance of success.
      </Paragraph>
      <Paragraph>
        Either way, the journey's what is worth. <i>C'est la vie!</i>
      </Paragraph>
    </Article>
  )
}
