import { Helmet } from 'react-helmet-async'
import Article from '../components/Article'
import Image from '../components/Article/Image'
import TitleSection from '../components/Article/TitleSection'
import Paragraph from '../components/Article/Paragraph'
import Header from '../components/Article/Header'
import useScrollToTop from '../components/ScrollToTop'
// images
import futureLanding from '../images/articles/future_landing.png'
import franklinEnergy from '../images/articles/franklin_energy_dashboard.png'
import incentiveAggregator from '../images/articles/future_incentive_aggregator.png'

export default function FutureArticle() {
  useScrollToTop()
  return (
    <Article>
      <Helmet><title>Future | kevinchuang.dev</title></Helmet>
      <TitleSection
        title='Building a Cleaner Future with Future'
        sub='Future is a climate-tech startup that I co-founded with my CEO Javier Correa that embarked on a mission to decarbonize millions of American buildings through the deployment of financial incentives.'
        dateTime='2024-02-24'
        dateStr='Feb 24, 2024'
      />
      <Image src={futureLanding} caption='Future landing page.' />
      <Header>Beginnings</Header>
      <Paragraph>
        I met Javier on Y Combinator's fantastic Co-Founder Matching Platform in early 2023, upon which we heavily vetted each other and found significant alignment on our shared mission to fight climate change.
      </Paragraph>
      <Paragraph>
        We complemented each other in a lot of ways. What I lacked in business development experience he made up for and vice versa when it came to building software and tech. As such, I became CTO to his CEO.
        Along with two other designers on the team, we were a lean, yet formidable team poised to tackle the climate problem head-on with the flood of IRA funds soon to be unlocked.
      </Paragraph>
      <Paragraph>
        Prior to Future, I had founded two other startups: one in edtech and the other in social media. In both cases, I was a solo founder that enlisted the help of friends to help materialize each startup's mission.
        I bring with me nearly a decade of gritty, self-taught full-stack experience and the confidence to build out any software that Future will ever need.
      </Paragraph>
      <Header>Helping HVAC Contractors Deploy Incentives to Homeowners</Header>
      <Paragraph>
        Javier's water heater broke early in the year, so naturally, he found a contractor to come replace it.
        What he quickly found out was that 70%+ of contractors did not know anything about existing financial incentives, especially those geared towards heat pump upgrades.
      </Paragraph>
      <Paragraph>
        For the uninitiated, heat pumps offer much higher efficiency and lower carbon footprint compared to traditional heating/cooling units. The main reason most of us don't have them is due to the large upfront cost, which incentives can help with.
      </Paragraph>
      <Paragraph>
        After many a call and interview with HVAC contractors, I built a simple MVP that qualified millions of appliances (specifically HVAC units and heat pumps) against various financial incentives.
        Using the robust search tool in the product, contractors could quickly see exactly which appliances qualified or not, informing them on specific heat pumps to pursue for their customers.
      </Paragraph>
      <Paragraph>
        As with all of Future's MVPs, I opted for a single EC2 instance on AWS running node.js, MongoDB, and whatever else a specific product needed.
        The primary reasoning is to optimize for time to market without sacrificing too much in the realm of tech debt.
        The frontend is built with React and ChakraUI for rapid prototyping. I only introduced state management libraries later on when needed.
      </Paragraph>
      <Header>From Contractors to Utilities to ICs: Implementation Contractors</Header>
      <Paragraph>
        We failed to find sufficient pull from HVAC contractors with the appliance qualifier, so we quickly turned towards working with utility companies directly as many of them run their own incentive programs directed at heat pump adoption.
        What we found was that utilities just put out specs for their incentive programs, but hired implementation contractors, or ICs, to actually run the programs.
      </Paragraph>
      <Paragraph>
        We quickly pivoted and worked closely with several ICs, eventually securing a few pilot programs with them.
        The deal was for them to use our program automation platform to shave 90% off their incentive processing time.
      </Paragraph>
      <Paragraph>
        I'm used to designing and building product from the ground up, but since we have designers on the Future team, they freed me up to focus on the implementation side of things.
      </Paragraph>
      <Image src={franklinEnergy} caption='Incentive program automation dashboard for Franklin Energy, an IC.' />
      <Paragraph>
        There's a lot going on in this dashboard, so I'll summarize real quick. The navbar is on the left with the "Upload Application" button being of note.
        In the center, we track all applications for the IC's utility client. These applications are sent in by HVAC contractors that usually just installed a heat pump for someone.
        A user at the IC then has the ability to review and approve applications as they come in.
        Useful KPIs in the right modules round out this simple dashboard design.
      </Paragraph>
      <Header>Workflow Automation with AWS Textract</Header>
      <Paragraph>
        Earlier, I mentioned 90%+ time savings for ICs. How do we achieve that? The answer lies in the most time-consuming aspect of the approval process: handwritten applications.
      </Paragraph>
      <Paragraph>
        When a HVAC contractor and their homeowner client apply for a financial incentive from a utility, they jointly fill out forms provided by the homeowner's utility company.
        The problem is, this process is usually done by hand on-prem by contractors. There may be digital components to these forms, but they are often disjointed and supplemental to handwritten pieces of paper.
      </Paragraph>
      <Paragraph>
        Prior to our solution, ICs dedicate a couple employees to sifting through every single application manually, cross-checking values for accuracy.
        This is where we saw opportunity to dramatically speed up the incentive approval process from a couple months down to just a few days.
      </Paragraph>
      <Paragraph>
        The secret here is utilizing text recognition services offered by AWS, specifically AWS Textract.
        This powerful API allows us to feed it pictures of handwritten application forms then extract all useful values from it.
      </Paragraph>
      <Paragraph>
        The trickiest part of this flow in AWS was handling the asynchronous nature of the API.
        After images are sent to Textract, I have to wait a few seconds for the processing to finish before processing the output values.
        I ended up having to use AWS S3, SNS, and SQS to store raw images and provide webhooks after Textract finishes processing images.
      </Paragraph>
      <Paragraph>
        Of course, logging and security were important IC considerations, so we tracked and persisted all raw applications through our system while pursuing SOC3 compliance.
      </Paragraph>
      <Header>Going for Broke: IRA Tax Credits, Grants, and Loans</Header>
      <Paragraph>
        While ICs were really interested in the automation dashboard we built, after speaking with investors and advisors, we realized we could not just serve the IC market for the simple reason that it is not venture backable.
        Unfortunately, as a young startup, we did not have the luxury of long runways to spend years playing around in different sectors.
        Without seed funding soon, we wouldn't be able to continue operating. The total addressable market of ICs in America is simply too small for most VCs to consider investing in.
      </Paragraph>
      <Paragraph>
        With that tough decision out the way, we took a hard look again at the incoming IRA's (Inflation Reduction Act) 10s of billions of dollars of incentives.
        The reality is that even outside of IRA funding, there exists innumerable financial incentives at all levels of government that remain untapped.
      </Paragraph>
      <Paragraph>
        Our mission remains the same: getting all this funding to the people that need it most.
        This time, we set our sights on early stage battery and greentech startups that could really use money (who doesn't?) but don't want to spend hours learning about the incentive landscape.
      </Paragraph>
      <Image src={incentiveAggregator} caption='AI-powered incentive aggregator that compiles incentives from a company URL.' />
      <Paragraph>
        This MVP is simple: offer a field where a prospective company can enter their company URL and let our fine-tuned OpenAI models do the heavy lifting.
        We trained our model on several incentives, typically federal tax credits or grants, then aggregate the incentives that the inquiring company can qualify for.
        Through a combination of prompt engineering and model fine-tuning, we were able to achieve 90%+ accuracy when suggesting qualifying incentives.
      </Paragraph>
      <Paragraph>
        We felt a lot of pull from potential clients when showing this MVP to them.
        The plan was to demo this to prospects, then provide white glove treatment to help them obtain a few select incentives.
        We had 2-3 companies on board with us at this point, but ultimately could not help them unlock incentives in time due to the timeline of these programs.
        For example, one specific grant had a multi-month long application process upon which funds would be available over half a year later, far beyond Future's remaining runway.
      </Paragraph>
      <Header>Learnings</Header>
      <Paragraph>
        Embarking on this journey with Javier and the team was a whirlwind of high highs and low lows.
        While we're in the process of moving on from this stage in our careers, I have a lot of takeaways from this venture.
      </Paragraph>
      <Paragraph>
        While I owned the technical side of things, it was also my responsibility to help perform outreach as well as determine the startup's strategic trajectory.
        Javier and I worked brilliantly well together, but clashes were inevitable, especially surrounding pivot decisions.
      </Paragraph>
      <Paragraph>
        Luckily, we always turned to the data every time to inform us of the best next move.
        It just so happened that our short runway was a huge constraint that potentially caused us to overlook opportunities in spaces that we explored.
      </Paragraph>
      <Paragraph>
        I also learned a lot from the technical side. Whether I was optimizing MongoDB queries for our appliance qualifier with millions of items, wrangling with AWS Textract to extract value out of handwritten forms, or training models on specific incentive programs, I confidently leaned on my proven track record of learning and solving every technical problem I encounter.
      </Paragraph>
      <Paragraph>
        Being a self-taught self-starter was how I began my full-stack journey. I have a feeling it will continue to serve me well in the years to come.
      </Paragraph>
    </Article>
  )
}
