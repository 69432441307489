import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

export default function Error() {
  return (
    <>
      <Helmet><title>Not Found | kevinchuang.dev</title></Helmet>
      <h1>404 - Page Not Found</h1>
      <Link to='/'>Return Home</Link>
    </>
  )
}
