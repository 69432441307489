import styled from 'styled-components'
import theme from '../../../themes/default'
import arrow from '../../../images/arrow.svg'
import optiswap from '../../../images/optiswap.png'
import pollific from '../../../images/pollific_screenshot.png'
import franklinDashboard from '../../../images/franklin_energy_dashboard.png'

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin: 0 2rem;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 480px) {
    grid-gap: 1rem;
    margin: 0 0.5rem;
  }
`

const ProjectCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 18px;
  background: ${theme.moduleBg};
  color: white;
  overflow: hidden;
  z-index: 1;
  transition: 0.2s;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`

const Title = styled.p`
  margin: 0;
  padding: 0 2rem;
  font-size: 1.8rem;
  font-weight: 500;
  opacity: 0.95;
`

const Arrow = styled.img`
  margin-right: 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  box-shadow: 0 0 0 2px white;
  transform: rotate(-45deg);
  opacity: 0.7;
  transition: 0.2s;
`

const Sub = styled.p`
  display: flex;
  align-items: center;
  width: 80%;
  height: 1.5rem;
  margin: 0;
  padding: 1rem 2rem 2rem 2rem;
  color: #eee;
  opacity: 0.9;
`

const Screenshot = styled.img`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  object-fit: cover;
  object-position: top;
  height: 186px;
  opacity: 0.7;
  transition: 0.2s;
`

const BG = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #a44fca 0%, #1CB5E0 100%);
  border-radius: 18px;
  transform: translateY(-100%);
  z-index: 0;
  transition: 0.2s;
`

const ProjectLink = styled.a`
  text-decoration: none;
  &:hover ${ProjectCard} {
    transform: translate(8px, -8px) rotate(1deg);
  }
  &:hover ${Title},
  &:hover ${Sub},
  &:hover ${Screenshot} {
    opacity: 1;
  }
  &:hover ${Arrow} {
    opacity: 1;
    filter: brightness(85%) sepia(10) saturate(10) hue-rotate(135deg);
  }
  &:hover ${BG} {
    box-shadow: 0 0 8px 1px #a44fca;
  }
  @media (max-width: 480px) {
    &:hover ${ProjectCard} {
      transform: translate(4px, -4px) rotate(1deg);
    }
  }
`

export default function Projects() {
  return (
    <Wrapper>
      <ProjectLink href='/#/projects/optiswap'>
        <ProjectCard>
          <TitleRow>
            <Title>OptiSwap</Title>
            <Arrow src={arrow} width='24' />
          </TitleRow>
          <Sub>Optimized Uniswap wrapper for ERC20 tokens</Sub>
          <Screenshot src={optiswap} />
        </ProjectCard>
        <BG />
      </ProjectLink>
      <ProjectLink href='/#/projects/future'>
        <ProjectCard>
          <TitleRow>
            <Title>Future</Title>
            <Arrow src={arrow} width='24' />
          </TitleRow>
          <Sub>Decarbonizing buildings through incentives</Sub>
          <Screenshot src={franklinDashboard} />
        </ProjectCard>
        <BG />
      </ProjectLink>
      <ProjectLink href='/#/projects/pollific'>
        <ProjectCard>
          <TitleRow>
            <Title>Pollific</Title>
            <Arrow src={arrow} width='24' />
          </TitleRow>
          <Sub>Real-time social polling platform</Sub>
          <Screenshot src={pollific} />
        </ProjectCard>
        <BG />
      </ProjectLink>
    </Wrapper>
  )
}
