import styled, { keyframes } from 'styled-components'
import airplane from '../../../images/airplane.svg'

const halfPlaneSize = 36

const trajectory = keyframes`
  0% {
    left: calc(35% - ${halfPlaneSize}px);
    top: calc(35% - ${halfPlaneSize}px);
  }
  33% {
    left: calc(65% - ${halfPlaneSize}px);
    top: calc(65% - ${halfPlaneSize}px);
  }
  70% {
    left: calc(40% - ${halfPlaneSize}px);
    top: calc(55% - ${halfPlaneSize}px);
  }
  100% {
    left: calc(35% - ${halfPlaneSize}px);
    top: calc(35% - ${halfPlaneSize}px);
  }
`

const Airplane = styled.img`
  position: relative;
  left: calc(50% - ${halfPlaneSize}px);
  top: calc(50% - ${halfPlaneSize}px);
  animation: ${trajectory} infinite 12s ease-in-out;
`

const streaks = []

function getRandom(min, max) {
  return Math.random() * (max - min + 1) + min
}

function getRandomInt(min, max) {
  return Math.floor(getRandom(min, max))
}

for (let i = 0; i < 25; i++) {
  const randomLength = getRandomInt(2, 48)
  const isTopAxis = Math.floor(Math.random() * 2) // true for top axis, false for right axis
  const initOffset = getRandomInt(0, 90)
  const streakKeyframes = keyframes`
    0% {
      top: ${isTopAxis ? 0 : initOffset}%;
      right: ${isTopAxis ? initOffset : 0}%;
      transform: translate(${randomLength}px, -${randomLength}px) rotate(-45deg);
    }
    100% {
      top: ${isTopAxis ? 100 - initOffset : 100}%;
      right: ${isTopAxis ? 100 : 100 - initOffset}%;
      transform: translate(-${randomLength}px, ${randomLength}px) rotate(-45deg);
    }
  `
  const Streak = styled.div`
    position: absolute;
    width: ${randomLength}px;
    height: ${randomLength}px;
    border-top: solid 1px ${Math.floor(Math.random() * 2) ? 'white' : '#aaa'};
    transform: translate(${randomLength}px, -${randomLength}px) rotate(-45deg);
    animation: ${streakKeyframes} infinite ${0.2 / (Math.log(initOffset) / 15)}s linear;
    animation-delay: ${getRandom(0, 6)}s;
  `
  streaks.push(Streak)
}

export default function Fly() {
  return (
    <>
      {
        streaks.map((Streak, i) => <Streak key={i} />)
      }
      <Airplane src={airplane} width={halfPlaneSize * 2} />
    </>
  )  
}
