import styled from 'styled-components'
import theme from '../../../themes/default'
import TopRow from './TopRow'
import IntroPanel from './IntroPanel'

const Wrapper = styled.div`
  position: relative;
  margin: 2rem 2rem 0 2rem;
  border-radius: 18px;
  overflow: hidden;
  @media (max-width: 480px) {
    margin: 0.5rem 0.5rem 0 0.5rem;
  }
`

const Glow = styled.div`
  position: absolute;
  top: 20%;
  left: 0.5rem;
  border-radius: 50%;
  box-shadow: 0 0 10rem 10rem ${theme.galaxyPurpleLight}ee;
  z-index: 1;
  @media (max-width: 968px) {
    top: 40%;
  }
  @media (max-width: 768px) {
    top: 40%;
  }
`

export default function MainSection() {
  return (
    <Wrapper>
      <Glow />
      <TopRow />
      <IntroPanel />
    </Wrapper>
  )
}
