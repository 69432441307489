import { Helmet } from 'react-helmet-async'
import Article from '../components/Article'
import TitleSection from '../components/Article/TitleSection'
import Image from '../components/Article/Image'
import Paragraph from '../components/Article/Paragraph'
import Header from '../components/Article/Header'
import useScrollToTop from '../components/ScrollToTop'
// images
import optiswap from '../images/optiswap.png'
import optiswapBefore from '../images/articles/original_optiswap_design.png'
import optiswapAfter from '../images/articles/optiswap_PR_submission.png'
import bonusswapBefore from '../images/articles/original_bonusswap_design.png'
import bonusswapAfter from '../images/articles/bonusswap_PR_submission.png'

export default function OptiswapArticle() {
  useScrollToTop()
  return (
    <Article>
      <Helmet><title>OptiSwap | kevinchuang.dev</title></Helmet>
      <TitleSection
        title='Overhauling legacy UIs for an Optimized Uniswap Wrapper'
        sub='OptiSwap is a DeFi trading layer built on top of Uniswap while improving or adding useful features. Most notably, the OptiSwap product calculates optimal trade quantities for ERC20 tokens while preventing bot front-running.'
        dateTime='2024-04-12'
        dateStr='Apr 12, 2024'
      />
      <Image src={optiswap} caption='OptiSwap featuring ETH/AUDIO trading pair.' />
      <Paragraph>
        When I found out about this platform, I felt like the UI could use a refresh.
        So, I reached out to the team, got access to the codebase, and started designing a new version of the trading terminal.
        You can see the original UI below, primarily built with React, Tailwind, and Solidity.
      </Paragraph>
      <Image src={optiswapBefore} caption='Original OptiSwap design with additional order analysis.' />
      <Header>We Can Do Better</Header>
      <Paragraph>
        First thing's first. It's clear that in addition to having basic crypto trading utilities, OptiSwap needs to communicate optimal trade sizing to the user.
        My challenge is to integrate all of OptiSwap's extra features into one clean and seamless interface that remains intuitive for even first-time users to pick up on.
      </Paragraph>
      <Paragraph>
        I opt to reverse the left and right sides in order to adhere to the convention of having the trading panel on the right-hand side.
        Then, I condense all the good parts of order analysis into one single module while also removing the redundant "Use Optimal Size" button.
        I also remove the dedicated wallet balance section, instead opting to integrate that info directly into the trading panel.
        What follows is the final product.
      </Paragraph>
      <Image src={optiswapAfter} caption='New OptiSwap design with example trade order.' />
      <Paragraph>
        <i>Don't worry - this wallet address is using an Ethereum testnet!</i>&nbsp;
        One thing to note is that I only focused on overhauling the center module and did not get around to refreshing other aspects of the page such as the token select dropdown.
      </Paragraph>
      <Header>The Good Stuff: Design Principles</Header>
      <Paragraph>
        To save space, I overlapped the token swap button (the up/down arrow symbol) with the token input fields and display relevant wallet balances directly on the top right corner of said fields.
        A simple buy/sell {'<token>'} button rounds out the right side, occupying the same space as its analogous "Use optimal size" button on the left panel.
        Rounding out the design, I display order analysis stats as a simple key-value list, highlighting a color-coded order realization percentage that visually indicates how close the user's trade is to optimal.
      </Paragraph>
      <Paragraph>
        Spacing between elements is highly intentional and symmetrical. The two trade input fields are separated by a small, but visible gap, while the spacing between the two bottom buttons and their respective content above is larger, but still indicate that they belong to the same panel.
        Finally, the gap between the left and right panels is the largest and also the same as the entire module's padding, indicating a clear left-right separation while still neatly containing both sides under the same DeFi roof.
      </Paragraph>
      <Header>But Wait, There's More! (just a little)</Header>
      <Paragraph>
        When you visit <a href='https://optiswap.pro' target='_blank' rel='noreferrer'>optiswap.pro</a>, you will see a grid of products to access, of which OptiSwap is one of them.
        There are several other features as part of the OptiSwap suite, and I took my skills to another one called BonusSwap.
      </Paragraph>
      <Image src={bonusswapBefore} caption='Original BonusSwap design.' />
      <Paragraph>
        BonusSwap differs from OptiSwap in that for participating ERC20 tokens, a percentage bonus is added to every buy order.
        Where that bonus originates from depends on the ERC20 project, but is usually a dev or community appropriated fund used to incentivize new or existing buyers. Think of it like a sale at the supermarket.
      </Paragraph>
      <Header>BonusSwap 2.0</Header>
      <Paragraph>
        Refreshing BonusSwap will be easier than OptiSwap for the simple fact that there is no order analysis.
        The user still has access to "Use optimal size", but the complexities of order analysis is replaced with just the bonus buy total as well as the bonus percent.
      </Paragraph>
      <Image src={bonusswapAfter} caption='New BonusSwap design.' />
      <Paragraph>
        To avoid reinventing the wheel, I port over the same trading panel from OptiSwap minus the token swap button (the bonus only applies to buys, not sells).
      </Paragraph>
      <Paragraph>
        For the left panel, I decide to add a little visual juice to the bonus buy with a clever gradient border.
        Doing this visually draws the user's gaze, emphasizing the importance of a bonus pool, how much the user can expect to get per buy, and enticing the user to buy the token and claim some of the bonus.
      </Paragraph>
      <Paragraph>
        Because the bonus buy section already has a flashy design, I opt to remove the "Use optimal size" button's color from OptiSwap to reduce visual noise.
        Doing so maintains a clean aesthetic while keeping emphasis on the most important feature here: the bonus buy.
      </Paragraph>
      <Header>Fin</Header>
      <Paragraph>
        That's it! I spent a few weeks working with the Opti team to propose and implement designs and look forward to many more users to gain value from using this live product in the time ahead.
      </Paragraph>
      <Paragraph>
        As mentioned before, you can check out <a href='https://optiswap.pro' target='_blank' rel='noreferrer'>optiswap.pro</a> and begin using its features for any supported ERC20 token.
      </Paragraph>
    </Article>
  )
}
