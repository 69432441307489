import styled from 'styled-components'

const Text = styled.p`
  width: 80%;
  font-weight: 500;
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    width: 95%;
  }
`

export default function Paragraph({ children }) {
  return (
    <Text>
      {children}
    </Text>
  )
}
