import { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import theme from '../../../themes/default'
import Fly from './Fly'
import suipeek from '../../../images/suipeek.png'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(63deg, ${theme.moduleBg} 50%, ${theme.skyBlue} 100%);
  border-radius: 0 18px 18px 18px;
  @media (max-width: 768px) {
    flex-direction: column;
    background: linear-gradient(115deg, ${theme.moduleBg} 45%, ${theme.skyBlue} 100%);
  }
`

const TextSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 55%;
  box-sizing: border-box;
  padding: 3rem;
  // box-shadow: 0 0 0 1px white inset;
  z-index: 1;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    padding: 1rem;
  }
`

const Name = styled.h1`
  margin: 0;
  font-weight: 300;
  font-size: 3.2rem;
`

const Sub = styled.p`
  font-size: 1.2rem;
  color: #eee;
`

const EmailBackground = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  width: fit-content;
  box-shadow: 0 0 0 2px ${theme.suiBlue};
  border-radius: 24px;
  transition: 0.1s;
  &:hover {
    transform: translate(3px, -3px);
    box-shadow: -3px 3px 0 1px #89ccff;
  }
`

const EmailCTA = styled.a`
  padding: 0.5rem 1rem;
  width: fit-content;
  color: transparent;
  background: linear-gradient(135deg, #dd91ff 0%, #80eaff 100%);
  border-radius: 24px;
  box-shadow: 0 0 0 1px ${theme.suiBlue};
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ImageSection = styled.div`
  position: relative;
  height: 18rem;
  width: 18rem;
  border-radius: 50%;
  // box-shadow: 0 0 8px 2px #aaa;
  overflow: hidden;
`

const Sui = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-100%) scale(-1, 1);
  transition: 0.2s;
  &.out {
    transform: translate(0) scale(-1, 1) !important;
  }
`

const Suichan = styled.p`
  position: absolute;
  margin: 0;
  bottom: -1rem;
  left: 2.4rem;
  color: ${theme.suiBlue};
  font-size: 0.7rem;
  font-style: italic;
  transition: 0.2s;
`

const suichan1Loop = keyframes`
  0% {
    bottom: -1rem;
  }
  14.5% {
    bottom: -1rem;
  }
  15% {
    bottom: 0.5rem;
  }
  19.5% {
    bottom: 0.5rem;
  }
  20% {
    bottom: -1rem;
  }
  100% {
    bottom: -1rem;
  }
`

const suichan2Loop = keyframes`
  0% {
    bottom: -1rem;
  }
  22.5% {
    bottom: -1rem;
  }
  23% {
    bottom: 0.5rem;
  }
  29.5% {
    bottom: 0.5rem;
  }
  30% {
    bottom: -1rem;
  }
  100% {
    bottom: -1rem;
  }
`

const Suichan1 = styled(Suichan)`
  animation: ${suichan1Loop} infinite 60s;
`

const Suichan2 = styled(Suichan)`
  animation: ${suichan2Loop} infinite 60s;
`

const SuiLink = styled.a`
  &:hover ${Sui} {
    transform: translate(5%, -10%) scale(-1.2, 1.2) !important;
  }
`

export default function IntroPanel() {
  const [outClass, setOutClass] = useState('')

  useEffect(() => {
    const timeout = setTimeout(() => { setOutClass('out') }, 1500)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Wrapper>
      <TextSection>
        <Name>Kevin Chuang</Name>
        <Sub>
          Sr. Full-Stack Engineer / Entrepreneur / Musician / AvGeek / Anime Enjoyer
        </Sub>
        <EmailBackground>
          <EmailCTA href='mailto:kevinchuang1@hotmail.com'>Let's talk!</EmailCTA>
        </EmailBackground>
      </TextSection>
      <ImageSection>
        <Fly />
      </ImageSection>
      <SuiLink href='https://www.youtube.com/watch?v=8ZP5eqm4JqM' target='_blank'>
        <Sui className={outClass} src={suipeek} width='36' />
      </SuiLink>
      <Suichan1>すいちゃんは～</Suichan1>
      <Suichan2>今日もかわい～！！</Suichan2>
    </Wrapper>
  )
}
