import styled from 'styled-components'
import theme from '../../themes/default'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: ${theme.backgroundBg};
`

const Children = styled.div`
  display: flex;
  align-items: center;
  max-width: 1024px;
`

export default function Background({ children }) {
  return (
    <Wrapper>
      <Children>
        {children}
      </Children>
    </Wrapper>
  )
}
