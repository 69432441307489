import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 1rem;
  width: 80%;
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    width: 95%;
  }
`

const Title = styled.h1`
  margin: 1rem 0;
  font-size: 2.2rem;
  opacity: 0.9;
`

const Sub = styled.p`
  margin: 0;
  width: 75%;
  font-size: 0.9rem;
  font-weight: 500;
  @media (max-width: 480px) {
    width: 85%;
  }
`

const Date = styled.p`
  font-size: 0.9rem;
  opacity: 0.7;
`

export default function TitleSection({ title, sub, dateTime, dateStr }) {
  return (
    <Container>
      <Title>{title}</Title>
      <Sub>{sub}</Sub>
      <Date dateTime={dateTime}>{dateStr}</Date>
    </Container>
  )
}
