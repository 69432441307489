import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
  @media (max-width: 768px) {
    margin: 1.5rem 0;
  }
`

const StyledImage = styled.img`
  width: 100%;
  flex-shrink: 0;
  object-fit: cover;
  object-position: top;
  border-radius: 18px;
  box-shadow: 0 0 8px 0 #bbb;
`

const Caption = styled.p`
  width: 80%;
  margin: 0.4rem 0 0 0;
  font-size: 0.8rem;
  font-weight: 700;
  opacity: 0.8;
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    width: 95%;
  }
`

export default function Image({ src, caption }) {
  return (
    <Wrapper>
      <StyledImage src={src} />
      <Caption>{caption}</Caption>
    </Wrapper>
  )
}
